import React, { useEffect, useRef, useState } from 'react';
import './Modal.scss';
import CrossIcon from '../../assets/images/CrossIcon';
import ArrowIcon from '../../assets/images/ArrowIcon';

interface IModal {
  children: React.ReactNode;
  isOpen: boolean;
  handleCloseModal: () => void;
}

const Modal: React.FC<IModal> = ({ children, isOpen, handleCloseModal }) => {

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        handleCloseModal();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);


  return (
    <div>
      {isOpen && (
        <div className='modal'>
          <div className='modal__content' ref={modalRef}>
            <button className='modal__close__btn' onClick={handleCloseModal}>
              <CrossIcon />
            </button>
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;