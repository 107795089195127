import React from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { YMInitializer } from 'react-yandex-metrika';

interface ILayout {
  children: React.ReactNode;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const Layout: React.FC<ILayout> = props => {
  const { children, handleClick } = props;

  return (
    <>
      <YMInitializer accounts={[96321154]} options={{ webvisor: true }} />
      <Header handleClick={handleClick} />
      <main className="main">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
