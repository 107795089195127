export default function MaterialsFive() {
  return (<svg width='512' height='162' viewBox='0 0 512 162' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M37.3603 154.5C36.9754 155.167 36.0132 155.167 35.6283 154.5L14.8437 118.5C5.22119 101.834 17.2493 81.0002 36.4943 81.0002C55.7393 81.0002 67.7675 101.834 58.145 118.5L37.3603 154.5Z'
        fill='#007BFB' />
      <circle cx='36.4944' cy='104.709' r='13.7913' fill='white' />
      <path
        d='M91.2581 76.9983C90.8732 77.6649 89.911 77.6649 89.5261 76.9983L82.3532 64.5744C78.7803 58.3861 83.2464 50.6506 90.3921 50.6506C97.5378 50.6506 102.004 58.3861 98.431 64.5744L91.2581 76.9983Z'
        fill='#007BFB' />
      <circle cx='90.3922' cy='59.4537' r='5.12072' fill='white' />
      <path
        d='M381.683 59.7146C381.298 60.3812 380.336 60.3812 379.951 59.7146L372.778 47.2908C369.205 41.1024 373.671 33.3669 380.817 33.3669C387.963 33.3669 392.429 41.1024 388.856 47.2908L381.683 59.7146Z'
        fill='#007BFB' />
      <circle cx='380.817' cy='42.17' r='5.12072' fill='white' />
      <path
        d='M477.947 79.5002C477.562 80.1669 476.6 80.1669 476.215 79.5002L455.431 43.5002C445.808 26.8336 457.836 6.00024 477.081 6.00024C496.326 6.00025 508.354 26.8336 498.732 43.5002L477.947 79.5002Z'
        fill='#007BFB' />
      <circle cx='477.081' cy='29.709' r='13.7913' fill='white' />
      <path
        d='M46.4065 153.984L132.719 126.949L206.183 141.958L397.405 117.639L262.171 53.8057L90.4751 81.8715L381.926 62.8408L246.486 113.713L468.939 81.8715'
        stroke='#007BFB' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' stroke-dasharray='5 5' />
      <path
        d='M247.652 110.848C247.267 111.515 246.305 111.515 245.92 110.848L238.747 98.4241C235.174 92.2357 239.64 84.5002 246.786 84.5002C253.931 84.5002 258.397 92.2357 254.825 98.4241L247.652 110.848Z'
        fill='#007BFB' />
      <circle cx='246.786' cy='93.3033' r='5.12072' fill='white' />
      <path
        d='M262.069 49.1506C261.684 49.8173 260.722 49.8173 260.337 49.1506L253.164 36.7268C249.591 30.5384 254.057 22.803 261.203 22.803C268.348 22.803 272.814 30.5384 269.242 36.7268L262.069 49.1506Z'
        fill='#007BFB' />
      <circle cx='261.203' cy='31.6061' r='5.12072' fill='white' />
      <path
        d='M133.196 124.772C132.811 125.438 131.848 125.438 131.464 124.772L124.291 112.348C120.718 106.16 125.184 98.4241 132.33 98.4241C139.475 98.4241 143.941 106.16 140.369 112.348L133.196 124.772Z'
        fill='#007BFB' />
      <circle cx='132.33' cy='107.227' r='5.12072' fill='white' />
      <path
        d='M207.011 138.696C206.626 139.362 205.664 139.362 205.279 138.696L198.106 126.272C194.533 120.083 198.999 112.348 206.145 112.348C213.291 112.348 217.757 120.083 214.184 126.272L207.011 138.696Z'
        fill='#007BFB' />
      <circle cx='206.145' cy='121.151' r='5.12072' fill='white' />
      <path
        d='M398.1 112.848C397.715 113.515 396.753 113.515 396.368 112.848L389.195 100.424C385.622 94.2357 390.088 86.5002 397.234 86.5002C404.38 86.5002 408.846 94.2357 405.273 100.424L398.1 112.848Z'
        fill='#007BFB' />
      <circle cx='397.234' cy='95.3033' r='5.12072' fill='white' />
      <path
        d='M322.875 98.924C322.49 99.5907 321.528 99.5907 321.143 98.924L313.97 86.5002C310.398 80.3119 314.864 72.5764 322.009 72.5764C329.155 72.5764 333.621 80.3119 330.048 86.5002L322.875 98.924Z'
        fill='#007BFB' />
      <circle cx='322.009' cy='81.3795' r='5.12072' fill='white' />
    </svg>
  );
}