// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin: 0 auto;
  padding: 64px;
}
@media (max-width: 1024px) {
  .container {
    padding: 23px;
  }
}`, "",{"version":3,"sources":["webpack://./src/hoc/Container/Container.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,aAAA;AADF;AAGE;EAJF;IAKI,aAAA;EAAF;AACF","sourcesContent":["@import \"../../scss/variables.scss\";\n\n.container {\n  margin: 0 auto;\n  padding: 64px;\n  \n  @media (max-width: $largeTablet) {\n    padding: 23px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
