import Container from '../../hoc/Container/Container';
import './Steps.scss';
import RhombusIcon from '../../assets/images/RhombusIcon';
import Step from './Step/Step';
import React, { useState } from 'react';
import Button from '../Button/Button';
import { stepCards } from './utils/constants';
import ArrowDownIcon from '../../assets/images/ArrowDownIcon';
import ym from 'react-yandex-metrika';

interface ISteps {
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const Steps: React.FC<ISteps> = ({ handleClick }) => {
  const [descriptionOpen, setDescriptionOpen] = useState(false);

  const handleOpenDescriptionBtnClick = () => {
    setDescriptionOpen(prev => !prev);
  };

  return (
    <section className="section section--white">
      <Container>
        <div className="steps">
          <div className="steps__title">
            Как проходит <span>месяц с наставником:</span>
          </div>
          <div className="steps__counter">
            10 этапов
            <RhombusIcon />
            <div className="steps__counter__line"></div>
          </div>
          <div className="steps__list">
            <div className={'step' + (descriptionOpen ? ' open' : '')}>
              <div className="step__count">
                <div className="step__count__value">1</div>
                <div className="step__count__line"></div>
              </div>
              <div className="step__line"></div>
              <div className="step__card">
                <div className="step__card__date">До 20 февраля</div>
                <button
                  className={
                    'step__card__btn' + (descriptionOpen ? ' open' : '')
                  }
                  onClick={handleOpenDescriptionBtnClick}>
                  <ArrowDownIcon />
                </button>
                <div className="step__card__text">
                  <div className="step__card__title">Старт сбора заявок</div>
                  <div className="step__card__description">
                    <p>
                      Заполните нашу <span onClick={handleClick}>форму</span>,
                      поделитесь, с какой проблемой столкнулись, на&nbsp;каком
                      этапе в&nbsp;развитии карьеры сейчас находитесь, какой
                      опыт хотите приобрести.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {stepCards.map(stepCard => {
              return (
                <Step
                  key={stepCard.id}
                  count={stepCard.id}
                  date={stepCard.date}
                  description={stepCard.description}
                  title={stepCard.title}
                />
              );
            })}
          </div>
          <Button
            onClick={event => {
              handleClick(event);
              ym('reachGoal', 'timeline-cta');
            }}>
            Оставить заявку
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default Steps;
