import React from 'react';

export enum CardSize {
  large = 'large',
  medium = 'medium',
  small = 'small',
}

export enum CardTheme {
  blue = 'blue',
  white = 'white',
  black = 'black',
  grey = 'grey',
}

export enum ImagePosition {
  middle = 'middle',
  right = 'right',
  top = 'top',
}