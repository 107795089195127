import React from 'react';
import MaterialOne from '../../../assets/images/materials/MaterialOne';
import MaterialTwo from '../../../assets/images/materials/MaterialTwo';
import MaterialThree from '../../../assets/images/materials/MaterialThree';
import MaterialsFour from '../../../assets/images/materials/MaterialsFour';
import MaterialsFive from '../../../assets/images/materials/MaterialsFive';
import MaterialsSix from '../../../assets/images/materials/MaterialsSix';
import MaterialsSeven from '../../../assets/images/materials/MaterialSeven';
import MaterialsEight from '../../../assets/images/materials/MaterialsEight';

interface IMaterialCard {
  id: number;
  title: string;
  description: string | React.ReactElement;
  image: React.ReactNode;
  file?: string;
}

export const materialCards: IMaterialCard[] = [
  {
    id: 1,
    title: 'Валентинки',
    description: (
      <p>
        Любит, не любит, любит! Мы&nbsp;вас&nbsp;любим уже заочно и&nbsp;дарим
        забавную валентинку от&nbsp;Самолета!
      </p>
    ),
    image: <MaterialOne />,
    file: 'https://drive.google.com/drive/folders/1kQX8cpfPiycfWu4q2reUzpdZ98usIpv9?usp=sharing',
  },
  {
    id: 2,
    title: 'Что почитать?',
    description:
      'Для тех, кто любит запах свежей литературы — список книг, которые точно понравятся любопытным умам',
    image: <MaterialTwo />,
    file: '/assets/materials/2.pdf',
  },
  {
    id: 3,
    title: 'Что посмотреть?',
    description: (
      <p>
        Мы принесли для вас список
        из&nbsp;пяти&nbsp;документалок,&nbsp;что&nbsp;маст&nbsp;си
        для&nbsp;приверженцев&nbsp;саморазвития
      </p>
    ),
    image: <MaterialThree />,
    file: '/assets/materials/3.pdf',
  },
  {
    id: 4,
    title: '16 вопросов',
    description: (
      <p>
        Хотите узнать о себе больше? Прощупать, кто вы без&nbsp;общественных
        ожиданий и&nbsp;чужих проекций? Ответьте на&nbsp;эти 16 вопросов
      </p>
    ),
    image: <MaterialsFour />,
    file: '/assets/materials/4.pdf',
  },
  {
    id: 5,
    title: 'Как построить свой карьерный бизнес-план',
    description: <p>Полезные инструменты от&nbsp;Евгения Большакова</p>,
    image: <MaterialsFive />,
    file: '/assets/materials/5.pdf',
  },
  {
    id: 6,
    title: 'Всё об обратной связи',
    description: (
      <p>
        Правила полезного фидбэка: Антон Елистратов рассказывает, как принимать
        и&nbsp;формировать обратную связь
      </p>
    ),
    image: <MaterialsSix />,
    file: '/assets/materials/6.pdf',
  },
  {
    id: 7,
    title: '«Бейсбольные карточки» твоей команды',
    description: (
      <p>
        Антон Елистратов о том, как&nbsp;SWOT-анализ помогает узнавать своих
        коллег
      </p>
    ),
    image: <MaterialsSeven />,
    file: '/assets/materials/7.pdf',
  },
  {
    id: 8,
    title: 'Как стать классным руководителем',
    description: (
      <p>
        Лайфхаки и упражения от&nbsp;Артёма Шустера
        и&nbsp;Вероники&nbsp;Трофимовой
      </p>
    ),
    image: <MaterialsEight />,
    file: '/assets/materials/8.pdf',
  },
];
