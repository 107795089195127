import React from 'react';

export default function InfinityIcon(): JSX.Element {
  return (
    <svg width='75' height='38' viewBox='0 0 75 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M74.52 19.55C74.52 29.01 68.03 37.37 56.7 37.37C47.9 37.37 42.62 32.09 37.89 25.27C34.37 30.55 27.77 37.37 18.42 37.37C7.42 37.37 0.6 29.01 0.6 19.55C0.6 8.44 7.53 0.959998 18.75 0.959998C26.89 0.959998 32.61 6.02 37.56 13.17C42.73 6.46 48.34 0.959998 56.92 0.959998C68.03 0.959998 74.52 8.99 74.52 19.55ZM9.07 19C9.07 25.6 13.91 29.23 19.3 29.23C25.24 29.23 30.19 23.51 32.61 19.66C29.09 14.6 24.69 9.1 18.86 9.1C14.02 9.1 9.07 12.4 9.07 19ZM56.37 9.1C51.53 9.1 47.46 12.84 42.62 19.22C46.36 24.5 50.98 29.23 56.59 29.23C62.2 29.23 66.05 24.94 66.05 19C66.05 13.06 61.76 9.1 56.37 9.1Z'
        fill='white' />
    </svg>

  );
}