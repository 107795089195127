import React, { useRef, useState } from 'react';
import Layout from '../../hoc/Layout/Layout';
import './Index.scss';
import Mentors from '../../components/Mentors/Mentors';
import Steps from '../../components/Steps/Steps';
import DirectorInfo from '../../components/DirectorInfo/DirectorInfo';
import Materials from '../../components/Materials/Materials';
import FAQ from '../../components/FAQ/FAQ';
import Request from '../../components/Request/Request';
import About from '../../components/About/About';
import Modal from '../../components/Modal/Modal';
import FormEmpty from '../../components/Form/FormEmpty';

const Index = () => {
  const materialsRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleCloseModal = () => {
    setIsOpen(false);
    document.body.style.overflow = '';
  };

  const handleClick = () => {
    setIsOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const scrollToFormSentBlock = () => {
    handleCloseModal();
    if (materialsRef.current) {
      materialsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <Layout handleClick={handleClick}>
      <About handleClick={handleClick} />
      <Mentors handleClick={handleClick} />
      <Steps handleClick={handleClick} />
      <DirectorInfo />
      <Materials materialsRef={materialsRef} />
      <Request handleClick={handleClick} />
      <FAQ handleClick={handleClick} />
      <Modal isOpen={isOpen} handleCloseModal={handleCloseModal}>
        <FormEmpty scrollToFormSentBlock={scrollToFormSentBlock} />
      </Modal>
    </Layout>
  );
};

export default Index;
