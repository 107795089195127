export default function MaterialTwo() {
  return (
    <svg width='310' height='131' viewBox='0 0 310 131' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g  clipPath='url(#clip0_336_2772)'>
        <rect width='309.333' height='130.333' transform='translate(0.333496)' fill='white' />
        <rect x='110' y='130' width='16' height='200' rx='2' transform='rotate(-90 110 130)' fill='#007BFB' />
        <rect x='110' y='130' width='16' height='200' rx='2' transform='rotate(-90 110 130)' fill='#007BFB' />
        <rect x='94' y='112' width='10' height='210' transform='rotate(-90 94 112)' fill='white' stroke='#007BFB'
               strokeWidth='1.5'  strokeLinecap='round'  strokeLinejoin='round'  strokeDasharray='5 5' />
        <g  clipPath='url(#clip1_336_2772)'>
          <rect x='3' y='60.2678' width='200' height='50' rx='2' transform='rotate(-6.10434 3 60.2678)'
                fill='#E0EFFF' />
          <rect x='3.85059' y='68.2224' width='8' height='200' rx='2' transform='rotate(-96.1043 3.85059 68.2224)'
                fill='#007BFB' />
          <rect x='8.31689' y='109.984' width='8' height='200' rx='2' transform='rotate(-96.1043 8.31689 109.984)'
                fill='#007BFB' />
        </g>
        <rect x='6.50928' y='56.4949' width='33' height='213.298' rx='2' transform='rotate(-96.1043 6.50928 56.4949)'
              fill='#007BFB' />
      </g>
      <defs>
        <clipPath id='clip0_336_2772'>
          <rect width='309.333' height='130.333' fill='white' transform='translate(0.333496)' />
        </clipPath>
        <clipPath id='clip1_336_2772'>
          <rect x='3' y='60.2678' width='200' height='50' rx='2' transform='rotate(-6.10434 3 60.2678)' fill='white' />
        </clipPath>
      </defs>
    </svg>

  );
}