const TelegramIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path  fillRule='evenodd'  clipRule='evenodd'
            d='M14.6745 2.75005C14.7176 2.54358 14.646 2.32989 14.487 2.1912C14.3281 2.05251 14.1067 2.01042 13.908 2.08112L1.70032 6.42408C1.47705 6.50351 1.32402 6.71012 1.31312 6.94685C1.30222 7.18357 1.43562 7.40337 1.65065 7.50297L4.63729 8.88648L5.46101 13.006C5.49971 13.1995 5.63363 13.3604 5.81694 13.4335C6.00025 13.5066 6.2081 13.482 6.36935 13.3682L8.72385 11.7068L11.5047 13.833C11.6639 13.9548 11.8745 13.9862 12.0623 13.9164C12.2502 13.8465 12.389 13.6852 12.43 13.489L13.4164 8.76922L12.2878 8.4668L11.4775 12.3436L9.09894 10.5249L7.81848 9.48379L11.1727 6.39791C11.3831 6.20435 11.4222 5.88684 11.2651 5.648C11.1079 5.40917 10.8009 5.31941 10.5398 5.436L5.15563 7.84083L3.4368 7.04461L13.3199 3.52863L12.6781 6.59942L13.8067 6.90184L14.6745 2.75005ZM6.11207 10.3119L5.81461 8.82425L7.75355 7.95823L6.53237 9.08172C6.45048 9.15706 6.39185 9.25425 6.36339 9.36183L6.11207 10.3119ZM7.77259 10.9501L7.00713 11.4903L7.26023 10.5335L7.77259 10.9501Z'
            fill='#F4F6FB' />
    </svg>

  );
};

export default TelegramIcon;