import './Form.scss';
import React from 'react';
import Button from '../Button/Button';

interface IForm {
  scrollToFormSentBlock: any;
}

const FormEmpty: React.FC<IForm> = ({ scrollToFormSentBlock }) => {
  return (
    <div className="form__modal">
      <div className="form-wrapper__form">
        <h2 className="form-wrapper__title">
          К сожалению, заявки больше не принимаются
        </h2>
        <Button onClick={scrollToFormSentBlock}>
          Перейти к полезным материалам
        </Button>
      </div>
    </div>
  );
};
export default FormEmpty;
