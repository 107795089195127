import './MaterialCard.scss';
import React from 'react';
import LoadFileIcon from '../../../assets/images/LoadFileIcon';

interface IMaterialCard {
  order: number;
  title: string;
  description: string | React.ReactElement;
  image: React.ReactNode;
  file?: string;
}

const MaterialCard: React.FC<IMaterialCard> = ({
  order,
  title,
  description,
  image,
  file,
}) => {
  return (
    <div className="material__card">
      <div className="material__card__header">
        <div className="material__card__order">{order}</div>
        <a href={file} target="_blank">
          <button className="material__card__btn">
            <LoadFileIcon />
          </button>
        </a>
      </div>
      <div className="material__card__image">{image}</div>
      <div className="material__card__title">{title}</div>
      <div className="material__card__description">{description}</div>
    </div>
  );
};

export default MaterialCard;
