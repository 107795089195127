import React from 'react';
import './Logo.scss';
import { Link } from 'react-router-dom';
import LogoIcon from '../../assets/images/LogoIcon';

const Logo: React.FC = (props) => {
  return (
    <div className='logo'>
      <Link to='/'>
        <LogoIcon />
      </Link>
    </div>
  );
};

export default Logo;
