import './Materials.scss';
import Container from '../../hoc/Container/Container';
import React, { LegacyRef, useState } from 'react';
import MaterialCard from './MaterialCard/MaterialCard';
import { materialCards } from './utils/constants';
import Button from '../Button/Button';

interface IMaterials {
  materialsRef: LegacyRef<HTMLElement>;
}

const Materials: React.FC<IMaterials> = ({materialsRef}) => {
  const [showMoreMaterials, setShowMoreMaterials] = useState(false);

  const handleShowMoreClick = () => {
    setShowMoreMaterials(prev => !prev);
  };

  return (
    <section className='section section--white' ref={materialsRef}>
      <Container>
        <div className='materials'>
          <div className='materials__title'>
            Полезные материалы <br /> от наставников
          </div>
          <div className={'materials__list' + (showMoreMaterials ? ' open' : '')}>
            {
              materialCards.map(materialCard => {
                return <MaterialCard
                  key={materialCard.id}
                  order={materialCard.id}
                  title={materialCard.title}
                  description={materialCard.description}
                  image={materialCard.image}
                  file={materialCard.file || ''}
                />;
              })}
          </div>
          <Button onClick={handleShowMoreClick}
                  className='button--white'>{showMoreMaterials ? 'Скрыть' : 'Показать еще'}</Button>

        </div>
      </Container>
    </section>
  );
};

export default Materials;