import React, { useState } from 'react';
import './MentorCard.scss';
import ArrowIcon from '../../../assets/images/ArrowIcon';
import CrossIcon from '../../../assets/images/CrossIcon';

interface MentorCard {
  name: string;
  description: JSX.Element;
  image: string;
  post: string;
}

const MentorCard: React.FC<MentorCard> = props => {
  const { name, description, image, post } = props;
  const [showDescription, setShowDescription] = useState<boolean>(false);

  const handleChangeCardButton = () => {
    setShowDescription(prev => !prev);
  };

  return (
    <div
      className={
        showDescription ? 'mentor__card mentor__card--active' : 'mentor__card'
      }>
      <div className="mentor__card--flip-container flip">
        <div className="flip__front" onClick={handleChangeCardButton}>
          <img
            className="mentor__card__image mentor__card__content"
            src={image}
            alt={name}
          />
          <div className="mentor__card__post">{post}</div>
          <button className="mentor__card__btn">
            <ArrowIcon />
          </button>
        </div>

        <div className="flip__back" onClick={handleChangeCardButton}>
          <div className="mentor__card__description mentor__card__content">
            {description}
          </div>
          <button className="mentor__card__btn">
            <CrossIcon />
          </button>
        </div>
      </div>
      <div className="mentor__card__name">{name}</div>
    </div>
  );
};

export default MentorCard;
