import React from 'react';

interface IFAQ {
  question: string | React.ReactElement;
  answer: string | React.ReactElement;
}

export const faqList: IFAQ[] = [
  {
    question: <p>Сколько стоит месяц с&nbsp;наставником?</p>,
    answer: (
      <p>
        Нисколько! Все на безвозмездной основе, ничего платить не&nbsp;нужно.
      </p>
    ),
  },
  {
    question: 'Специалистам из каких сфер смогут помочь наставники?',
    answer: (
      <div>
        Cоветы Антона по достижению целей в&nbsp;вертикальном росте — бесценны.
        <br />
        <br />
        Евгений с радостью поделится навыками горизонтального роста. <br />
        <br />
        На стрим к Артему приглашаем тех, кто&nbsp;только хочет стать
        руководителем.
        <br />
        <br />А тех, кто уже менеджерит команду — к&nbsp;Веронике.
      </div>
    ),
  },
  {
    question: (
      <p>Как вы выбираете, кому&nbsp;подарить месяц с&nbsp;наставником?</p>
    ),
    answer: (
      <p>
        Мы изучаем заявки участников и&nbsp;выбираем тех, кому опыт наставников
        может максимально помочь в текущей ситуации. Цель нашего спецпроекта —
        достичь конкретной цели, что&nbsp;возможно только при наличии
        необходимого опыта эксперта.
      </p>
    ),
  },
  {
    question: (
      <p>
        Как&nbsp;увеличить&nbsp;шансы&nbsp;на&nbsp;то, что выберут именно меня?
      </p>
    ),
    answer: (
      <p>
        Активно участвуйте в воркшопах, задавайте каверзные и&nbsp;не&nbsp;очень
        вопросы, делайте всё, чтобы наставник заметил вас
        еще&nbsp;до&nbsp;отбора заявок. Но главное — не забудьте грамотно
        сформулировать свой опыт, проблематику и цель, к&nbsp;которой хотите
        прийти с экспертом.
      </p>
    ),
  },
  {
    question: 'С какими запросами могут помочь наставники?',
    answer: (
      <p>
        Таких запросов целых четыре.
        Если&nbsp;вы&nbsp;хотите&nbsp;горизонтального или вертикального роста —
        отправляйтесь к&nbsp;Евгению и Антону. Если задумываетесь о том, как
        стать руководителем, или&nbsp;уже&nbsp;начали управлять своей командой —
        на стримах вас&nbsp;будут ждать Артем и Вероника.
      </p>
    ),
  },
  {
    question: (
      <p>
        Смогу ли я поддерживать связь с&nbsp;наставником после&nbsp;месяца,
        если&nbsp;это&nbsp;понадобится?
      </p>
    ),
    answer: (
      <p>
        Наставничество — это про партнерство. Совместный опыт может привести
        к&nbsp;началу новых рабочих отношений или&nbsp;даже&nbsp;к&nbsp;дружбе.
        Мы не исключаем, что&nbsp;после окончания проекта возможно дальнейшее
        сопровождение. Решение&nbsp;будет&nbsp;исходить&nbsp;от наставника :)
      </p>
    ),
  },
];
