import React from 'react';
import './Card.scss';
import Button from '../Button/Button';
import CardImage from './CardImage';
import { CardSize, CardTheme, ImagePosition } from '../../utils/constants';

interface ICard {
  title: string | JSX.Element;
  subtitle: string | JSX.Element;
  buttonClick?: (event: any) => void;
  buttonText?: string;
  theme: string;
  size?: string;
  image?: string;
  imagePosition?: string;
  buttonLink?: string;
  buttonClassName?: string;
}

const Card: React.FC<ICard> = (props) => {
  const {
    title,
    subtitle,
    theme = CardTheme.blue,
    size = CardSize.medium,
    image,
    imagePosition,
    buttonClick,
    buttonText,
    buttonLink = '',
    buttonClassName = 'button--white',
  } = props;

  const isButtonShow = Boolean(buttonText);
  const isButtonLink = Boolean(buttonLink);

  return (
    <div className={`card card--${theme} card--${size} card--image-${imagePosition}`}>
      <div className='card__left'>
        <div className='card__header'>
          <div className='card__title'>{title}</div>
          {(imagePosition === ImagePosition.top || imagePosition === ImagePosition.middle) &&
            <CardImage src={image} alt={'image'} position={imagePosition} />}
        </div>
        <div className='card__description'>
          {subtitle}
        </div>
        {isButtonShow
          ? isButtonLink
            ? <a href={buttonLink} target='_blank' className='card__link'>
              <Button className={buttonClassName}>
                {buttonText}
              </Button>
            </a>
            : <Button onClick={buttonClick} className={buttonClassName}>
              {buttonText}
            </Button>
          : null}
      </div>
      <div className='card__right'>
        {imagePosition === ImagePosition.right &&
          <CardImage src={image} alt={'image'} position={imagePosition} />}
      </div>

    </div>
  );
};

export default Card;
