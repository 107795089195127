import AntonElistratov from '../../../assets/images/mentors/AntonElistratov.png';
import EvgenyBolshakov from '../../../assets/images/mentors/EvgenyBolshakov.png';
import ArtemShuster from '../../../assets/images/mentors/ArtemShuster.png';
import MinkoVeronika from '../../../assets/images/mentors/MinkoVeronika.png';

interface IMentor {
  name: string;
  description: JSX.Element;
  image: string;
  post: string;
}

export const mentors: IMentor[] = [
  {
    name: 'Елистратов Антон',
    description: (
      <p>
        Расскажет&nbsp;на стриме про&nbsp;вертикальный рост, как&nbsp;успешно
        преодолевать внутренние&nbsp;и внешние кризисы&nbsp;и прийти к высоко
        поставленной&nbsp;цели.
      </p>
    ),
    image: AntonElistratov,
    post: 'Член совета директоров группы «Самолет»',
  },
  {
    name: 'Большаков Евгений',
    description: (
      <p>
        <span>Генеральный директор бизнес-юнита&nbsp;«Фонды». </span>
        Выбирайте его, если&nbsp;хотите&nbsp;развиваться
        в&nbsp;горизонтальной&nbsp;плоскости. Знает&nbsp;все о том, как стать
        крутым&nbsp;внутри одной специализации.&nbsp;Обладатель диплома МВА.
      </p>
    ),
    image: EvgenyBolshakov,
    post: 'CEO БЮ «Фонды»',
  },
  {
    name: 'Шустер Артем',
    description: (
      <p>
        <span>Директор по маркетингу бизнес-юнита&nbsp;«Самолет Банк». </span>
        Знает,&nbsp;что посоветовать тому,&nbsp;кто только хочет стать
        руководителем.&nbsp;Начал управлять&nbsp;командами на&nbsp;удалёнке
        задолго до&nbsp;ковида, был&nbsp;маркетинг-директором в&nbsp;МТС
        и&nbsp;Яндекс.Еде.
      </p>
    ),
    image: ArtemShuster,
    post: 'CMO БЮ «Самолет банк»',
  },
  {
    name: 'Трофимова Вероника',
    description: (
      <p>
        <span>
          Директор по внутренним продуктам, которые упрощают жизнь сотрудников.
        </span>{' '}
        С радостью поделится знаниями с&nbsp;теми, кто начинает свой путь
        руководителя. Успешно менеджерит проекты более 10 лет, создавала
        платформу Тинькофф Бизнес.
      </p>
    ),
    image: MinkoVeronika,
    post: 'CPO HR-tech',
  },
];
