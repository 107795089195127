import { CardSize, CardTheme, ImagePosition } from '../../../utils/constants';
import AntonElistratovIcon from '../../../assets/images/mentors/AntonElistratovIcon.png';
import React from 'react';
import WomanWithRhombus from '../../../assets/images/woman/WomanWithRhombus.png';

interface IDirectorInfoItem {
  title: string;
  subtitle: string | JSX.Element;
  image: string;
  imagePosition: string;
  theme: string;
  size: string;
  buttonText?: string;
  buttonLink?: string;
  buttonClassName?: string;
}

interface IDirectorInfo {
  director: IDirectorInfoItem;
  mentoringDescription: IDirectorInfoItem;
}

export const directorInfo: IDirectorInfo = {
  director: {
    title: 'Антон Елистратов',
    subtitle: 'Генеральный директор группы «Самолет»',
    theme: CardTheme.blue,
    size: CardSize.large,
    image: AntonElistratovIcon,
    imagePosition: ImagePosition.top,
    buttonText: 'Перейти в канал',
    buttonLink: 'https://t.me/+b5NueWMlH84wYmEy',
  },
  mentoringDescription: {
    title: 'Хотите видеть процесс наставничества изнутри?',
    subtitle: (
      <div>
        <div>
          Вся инсайдерская информация, процессы отбора заявок и&nbsp;подготовки
          к&nbsp;воркшопам, публикация итогов — тут, в&nbsp;телеграм-канале
          команды «Самолет». Наставники и эксперты поделится, как&nbsp;выглядит
          изнанка месяца наставничества и жизнь внутри карьеры.
        </div>
        <br />
        <div>
          Не стесняйтесь и подписывайтесь, чтобы&nbsp;не&nbsp;пропустить ничего
          важного.
        </div>
      </div>
    ),
    theme: CardTheme.black,
    size: CardSize.large,
    image: WomanWithRhombus,
    imagePosition: ImagePosition.middle,
    buttonText: 'Перейти в канал',
    buttonLink: 'https://t.me/+b5NueWMlH84wYmEy',
    buttonClassName: 'button--common',
  },
};
