const VkontakteIcon = () => {
  return (
    <svg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path  fillRule='evenodd'  clipRule='evenodd'
            d='M13.128 5.0624C13.5997 5.50995 14.0975 5.93103 14.5205 6.4237C14.7074 6.64264 14.8843 6.86857 15.0196 7.12265C15.2114 7.48384 15.0377 7.88131 14.7045 7.90288L12.633 7.90197C12.0988 7.94506 11.6726 7.73602 11.3142 7.381C11.0274 7.09709 10.7618 6.79493 10.486 6.50144C10.373 6.38148 10.2547 6.2686 10.1133 6.17939C9.83051 6.00099 9.58505 6.05561 9.42346 6.34225C9.25887 6.63379 9.22154 6.95661 9.20538 7.28152C9.18319 7.75559 9.03575 7.88022 8.54578 7.90192C7.49866 7.94991 6.50489 7.79595 5.5817 7.28257C4.7678 6.82998 4.13664 6.19105 3.5873 5.46772C2.5177 4.05924 1.69861 2.51156 0.962435 0.92051C0.796729 0.562045 0.917914 0.369623 1.32487 0.362813C2.00063 0.350054 2.67631 0.350962 3.35287 0.361905C3.62752 0.36581 3.80934 0.518912 3.91539 0.771086C4.281 1.6448 4.72837 2.47605 5.28991 3.24656C5.43945 3.45169 5.59194 3.65683 5.80908 3.80121C6.0493 3.96108 6.2322 3.90809 6.34521 3.64802C6.41692 3.48311 6.44831 3.30549 6.46447 3.12887C6.51801 2.52123 6.52507 1.91463 6.43117 1.30912C6.37352 0.931226 6.15451 0.686589 5.76652 0.615077C5.56853 0.578618 5.59801 0.507016 5.69387 0.397184C5.86037 0.207758 6.01697 0.0898438 6.32913 0.0898438H8.67019C9.03874 0.160538 9.12059 0.32145 9.17104 0.681867L9.17305 3.20919C9.16903 3.34872 9.24476 3.76276 9.50334 3.85511C9.7103 3.92085 9.84672 3.75994 9.97089 3.6324C10.5314 3.05423 10.9314 2.37094 11.2888 1.66341C11.4474 1.3523 11.5838 1.02921 11.716 0.706385C11.8139 0.466833 11.9675 0.348964 12.2452 0.354186L14.4983 0.356138C14.5651 0.356138 14.6327 0.357092 14.6974 0.367853C15.0771 0.430737 15.1811 0.58947 15.0639 0.949796C14.8791 1.51512 14.5196 1.98623 14.1681 2.45952C13.7924 2.96483 13.3905 3.45283 13.0179 3.96113C12.6755 4.42529 12.7027 4.65926 13.128 5.0624Z'
            fill='#F4F6FB' />
    </svg>
  );
};

export default VkontakteIcon;