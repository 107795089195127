import { CardSize, CardTheme, ImagePosition } from '../../../utils/constants';
import WomanOnSkateboard from '../../../assets/images/woman/WomanOnSkateboard.png';

interface IRequestCard {
  title: string | JSX.Element;
  subtitle: string | JSX.Element;
  image: string;
  imagePosition: string;
  theme: string;
  size: string;
  buttonText?: string;
  buttonLink?: string;
}

export const requestInfo: IRequestCard = {
  title: <div>Начните жить&nbsp;лучшую карьерную&nbsp;жизнь!</div>,
  subtitle: (
    <div>
      <div>
        Каждому из нас рано или поздно нужен опытный наставник, который твердо
        возьмет за руку и&nbsp;сократит запутанный карьерный лабиринт
        до&nbsp;простой и понятной прямой схемы.
      </div>
      <div>
        Оставьте заявку и выберите одного из&nbsp;четырех&nbsp;экспертов уже
        сейчас!
      </div>
    </div>
  ),
  theme: CardTheme.blue,
  size: CardSize.large,
  image: WomanOnSkateboard,
  imagePosition: ImagePosition.right,
  buttonText: 'Хочу к наставнику',
};
