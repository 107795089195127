import React from 'react';
import Container from '../../hoc/Container/Container';
import Card from '../Card/Card';
import { CardSize, ImagePosition } from '../../utils/constants';
import './About.scss';
import { ABOUT_CARDS } from './utils/constants';
import ym from 'react-yandex-metrika';

interface IAbout {
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const About: React.FC<IAbout> = ({ handleClick }) => {
  return (
    <section className="section section--grey">
      <Container>
        <div className="about">
          <Card
            title={ABOUT_CARDS.mentoringMonth.title}
            subtitle={ABOUT_CARDS.mentoringMonth.subtitle}
            buttonText={ABOUT_CARDS.mentoringMonth.buttonText}
            theme={ABOUT_CARDS.mentoringMonth.theme}
            buttonClick={event => {
              handleClick(event);
              ym('reachGoal', 'section-1-cta');
            }}
            image={ABOUT_CARDS.mentoringDates.img}
            imagePosition={ImagePosition.middle}
          />
          <Card
            title={ABOUT_CARDS.mentoringDates.title}
            subtitle={ABOUT_CARDS.mentoringDates.subtitle}
            theme={ABOUT_CARDS.mentoringDates.theme}
            image={ABOUT_CARDS.mentoringDates.img}
            imagePosition={ImagePosition.top}
          />
          <Card
            title={ABOUT_CARDS.workshop.title}
            subtitle={ABOUT_CARDS.workshop.subtitle}
            theme={ABOUT_CARDS.workshop.theme}
            size={CardSize.small}
          />
          <Card
            title={ABOUT_CARDS.meetings.title}
            subtitle={ABOUT_CARDS.meetings.subtitle}
            theme={ABOUT_CARDS.meetings.theme}
            size={CardSize.small}
          />
          <Card
            title={ABOUT_CARDS.mentors.title}
            subtitle={ABOUT_CARDS.mentors.subtitle}
            theme={ABOUT_CARDS.mentors.theme}
            size={CardSize.small}
          />
          <Card
            title={ABOUT_CARDS.careerValue.title}
            subtitle={ABOUT_CARDS.careerValue.subtitle}
            theme={ABOUT_CARDS.careerValue.theme}
            size={CardSize.small}
          />
        </div>
      </Container>
    </section>
  );
};

export default About;
