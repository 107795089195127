import React from 'react';

interface IStepCard {
  id: number;
  date: string;
  title: string | React.ReactElement;
  description: React.ReactElement;
}

export const stepCards: IStepCard[] = [
  {
    id: 2,
    date: 'До 20 февраля',
    title: <div>Присоединяйтесь к&nbsp;стримам</div>,
    description: (
      <p>
        Подписывайтесь на{' '}
        <a href="https://t.me/budujobs" target="_blank">
          БУДУ
        </a>
        , где будут проходить стримы от команды «Самолет». Можете выбрать,
        что&nbsp;откликается по&nbsp;направлению, а&nbsp;можете быть активным
        гостем на&nbsp;каждом воркшопе.
      </p>
    ),
  },
  {
    id: 3,
    date: '1 февраля',
    title: 'Вертикальный рост',
    description: (
      <p>
        Про вертикальный рост расскажет тот, кто&nbsp;в&nbsp;этом прекрасно
        разбирается —{' '}
        <a href="https://t.me/+b5NueWMlH84wYmEy" target="_blank">
          генеральный&nbsp;директор Самолета Антон Елистратов.
        </a>
      </p>
    ),
  },
  {
    id: 4,
    date: '6 февраля',
    title: 'Для начинающих руководителей',
    description: (
      <p>
        Если у вас уже есть команда людей в подчинении — Вероника Трофимова
        расскажет, как&nbsp;менеджерить их наиболее эффективно и&nbsp;сохранять
        дистанцию.
      </p>
    ),
  },
  {
    id: 5,
    date: '12 февраля',
    title: 'Горизонтальный рост',
    description: (
      <p>
        Нет желания расти вверх, но&nbsp;хочется расширять компетенции? В таком
        случае — велком на&nbsp;стрим про горизонтальный рост к&nbsp;Большакову
        Евгению.
      </p>
    ),
  },
  {
    id: 6,
    date: '20 февраля',
    title: 'Кто хочет стать руководителем',
    description: (
      <p>
        Только хотите стать руководителем? Смелее! Артем Шустер поделится
        секретами, с помощью каких инструментов этого можно достичь.
      </p>
    ),
  },
  {
    id: 7,
    date: 'До 27 февраля',
    title: 'Выбор победителей',
    description: (
      <p>
        Скрестите пальчики и&nbsp;ожидайте заветное письмо: выбор победителя
        среди участников продлится всего до 23 февраля. В&nbsp;это&nbsp;время
        наставники решат, кому их скиллы будут&nbsp;полезней всего.
      </p>
    ),
  },
  {
    id: 8,
    date: '1 марта',
    title: <div>Знакомство с&nbsp;наставниками</div>,
    description: (
      <p>
        Назначаем встречи с&nbsp;наставниками, знакомимся и выстраиваем планы
        по&nbsp;дальнейшей эффективной работе.
      </p>
    ),
  },
  {
    id: 9,
    date: 'До 20 марта',
    title: 'Встречи one-to-one',
    description: (
      <p>
        Встречи с наставниками one-to-one — то, ради чего мы здесь собрались.
        Четверо счастливчиков получат суперценные знания на личных встречах
        и&nbsp;мощный карьерный заряд&nbsp;на 2024 год.
      </p>
    ),
  },
  {
    id: 10,
    date: 'До 10 апреля',
    title: 'Делимся результатами',
    description: (
      <p>
        Делимся результатами наставничества в&nbsp;публичном
        поле:&nbsp;рассказываем про&nbsp;точки&nbsp;А&nbsp;и&nbsp;Б,
        подсвечиваем&nbsp;интересные моменты&nbsp;кейсов.
      </p>
    ),
  },
];
