export default function MaterialsFour() {
  return (
    <svg width="512" height="153" viewBox="0 0 512 153" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g  clipPath="url(#clip0_336_2741)">
        <rect width="512" height="152.333" transform="translate(0 0.333496)" fill="white"/>
        <rect x="372" y="13" width="140" height="60" rx="30" fill="#E0EFFF"/>
        <rect x="372" y="83" width="140" height="60" rx="30" fill="#007BFB"/>
        <rect x="380" y="21" width="44" height="44" rx="22" fill="white"/>
        <rect x="460" y="91" width="44" height="44" rx="22" fill="white"/>
        <path d="M367 105.186C367 133.979 346.385 152 311.752 152C266.812 152 253 124.657 253 82.6071C253 28.3357 274.646 7 312.165 7C341.438 7 359.373 19.0143 365.763 43.6643L341.85 48.6357C338.964 38.2786 329.481 29.1643 312.371 29.1643C291.344 29.1643 277.325 41.3857 276.913 74.9429C284.335 65.6214 297.528 60.0286 315.051 60.0286C344.942 60.0286 367 72.8714 367 105.186ZM310.928 80.95C291.55 80.95 280.418 89.2357 280.418 105.186C280.418 120.929 292.168 129.836 311.134 129.836C330.512 129.836 341.438 121.343 341.438 105.393C341.438 89.4429 330.718 80.95 310.928 80.95Z" fill="#007BFB"/>
        <path d="M244.999 126.667H214.399V12.6667M244.999 148.667H154.4M190.399 126.667V37.4667L158.4 55.0667M147 35.6667L187.199 12.6667" stroke="#007BFB"  strokeWidth="1.5"  strokeLinecap="round"  strokeLinejoin="round"  strokeDasharray="5 5"/>
        <circle cx="147" cy="35.6667" r="3" fill="#007BFB"/>
        <circle cx="157.962" cy="54.9756" r="3" fill="#007BFB"/>
        <circle cx="190.352" cy="37.7844" r="3" fill="#007BFB"/>
        <circle cx="190.352" cy="126.558" r="3" fill="#007BFB"/>
        <circle cx="154.261" cy="126.558" r="3" fill="#007BFB"/>
        <circle cx="154.261" cy="148.667" r="3" fill="#007BFB"/>
        <circle cx="244.999" cy="148.667" r="3" fill="#007BFB"/>
        <circle cx="244.999" cy="126.558" r="3" fill="#007BFB"/>
        <circle cx="187.352" cy="12.6667" r="3" fill="#007BFB"/>
        <circle cx="214.384" cy="12.6667" r="3" fill="#007BFB"/>
        <circle cx="214.384" cy="126.558" r="3" fill="#007BFB"/>
        <path d="M144.698 28.8667H146.248L146.078 29.6667H142.038L142.208 28.8667H143.868L144.928 23.7567L143.008 24.6367L142.668 23.9667L144.928 22.8667H145.948L144.698 28.8667Z" fill="#007BFB"/>
        <path d="M189.145 2.2735C189.145 1.5935 188.635 1.1935 187.785 1.1935C186.765 1.1935 186.125 1.7635 185.785 2.7635L184.985 2.5635C185.385 1.2335 186.355 0.433496 187.885 0.433496C189.175 0.433496 190.035 1.1135 190.035 2.1835C190.035 3.4435 189.105 4.2935 187.435 5.3335L185.495 6.5335H189.485L189.315 7.3335H184.175L184.375 6.3935L187.055 4.7035C188.505 3.7835 189.145 3.1935 189.145 2.2735Z" fill="#007BFB"/>
        <path d="M214.336 7.4335C212.946 7.4335 211.986 6.8235 211.776 5.5335L212.576 5.3835C212.746 6.2335 213.346 6.6735 214.326 6.6735C215.476 6.6735 216.246 6.0535 216.246 5.1635C216.246 4.4935 215.756 4.0135 214.786 4.0135C214.476 4.0135 214.106 4.0635 213.886 4.1135L213.656 3.4535L216.806 1.3335H212.926L213.096 0.533496H217.896L217.666 1.5735L214.906 3.3935C214.986 3.3835 215.066 3.3835 215.136 3.3835C216.296 3.3835 217.106 4.0535 217.106 5.0735C217.106 6.4835 216.056 7.4335 214.336 7.4335Z" fill="#007BFB"/>
        <path d="M224.336 114.758L223.416 119.178H224.406L224.246 119.938H223.256L222.916 121.558H222.106L222.446 119.938H218.686L218.886 118.988L223.186 114.758H224.336ZM219.696 119.178H222.606L223.356 115.578L219.696 119.178Z" fill="#007BFB"/>
        <path d="M243.859 114.758H248.009L247.839 115.558H244.379L243.739 117.618C244.159 117.358 244.729 117.228 245.249 117.228C246.529 117.228 247.419 117.918 247.419 119.158C247.419 120.818 246.199 121.658 244.649 121.658C243.389 121.638 242.389 121.018 242.189 119.838L243.009 119.698C243.189 120.468 243.779 120.898 244.729 120.898C245.859 120.898 246.559 120.238 246.559 119.278C246.559 118.448 246.039 117.958 245.029 117.958C244.479 117.958 243.889 118.158 243.529 118.478L242.789 118.238L243.859 114.758Z" fill="#007BFB"/>
        <path d="M255.292 142.276C255.292 143.936 254.042 144.776 252.502 144.776C251.082 144.776 250.192 144.056 250.192 142.616C250.192 140.756 250.852 137.776 253.572 137.776C254.672 137.776 255.612 138.246 255.862 139.486L255.062 139.646C254.852 138.876 254.332 138.536 253.502 138.536C252.202 138.536 251.402 139.746 251.162 141.086C251.492 140.696 252.182 140.346 253.092 140.346C254.392 140.346 255.292 141.036 255.292 142.276ZM252.882 141.086C251.762 141.086 251.022 141.766 251.022 142.666C251.022 143.566 251.632 144.016 252.582 144.016C253.712 144.016 254.412 143.356 254.412 142.396C254.412 141.576 253.902 141.086 252.882 141.086Z" fill="#007BFB"/>
        <path d="M146.49 144.676L150.96 138.676H146.89L147.06 137.876H151.92L151.69 138.966L147.49 144.676H146.49Z" fill="#007BFB"/>
        <path d="M145.79 119.908C145.79 119.208 146.24 118.258 147.43 118.018C146.9 117.778 146.56 117.248 146.56 116.708C146.56 116.008 147 114.658 149.39 114.658C151.05 114.658 151.72 115.418 151.72 116.358C151.72 117.248 151.13 117.938 150.22 118.148C150.8 118.358 151.19 118.858 151.19 119.448C151.19 120.448 150.64 121.658 148.24 121.658C146.59 121.658 145.79 120.888 145.79 119.908ZM148.94 117.748C150.49 117.748 150.87 117.008 150.87 116.408C150.87 115.798 150.31 115.398 149.34 115.398C147.79 115.398 147.41 116.138 147.41 116.698C147.41 117.348 147.99 117.748 148.94 117.748ZM148.7 118.428C147.06 118.428 146.66 119.208 146.66 119.848C146.66 120.488 147.24 120.918 148.28 120.918C149.92 120.918 150.32 120.128 150.32 119.538C150.32 118.848 149.71 118.428 148.7 118.428Z" fill="#007BFB"/>
        <path d="M178.172 117.158C178.172 115.498 179.422 114.658 180.962 114.658C182.382 114.658 183.272 115.378 183.272 116.818C183.272 118.678 182.612 121.658 179.892 121.658C178.792 121.658 177.852 121.188 177.602 119.948L178.402 119.788C178.612 120.558 179.132 120.898 179.962 120.898C181.262 120.898 182.062 119.688 182.302 118.348C181.972 118.738 181.282 119.088 180.372 119.088C179.072 119.088 178.172 118.398 178.172 117.158ZM180.582 118.348C181.702 118.348 182.442 117.668 182.442 116.768C182.442 115.868 181.832 115.418 180.882 115.418C179.752 115.418 179.052 116.078 179.052 117.038C179.052 117.858 179.562 118.348 180.582 118.348Z" fill="#007BFB"/>
        <path d="M178.867 52.1756H180.417L180.247 52.9756H176.207L176.377 52.1756H178.037L179.097 47.0656L177.177 47.9456L176.837 47.2756L179.097 46.1756H180.117L178.867 52.1756ZM183.535 53.0756C182.065 53.0756 181.315 52.1056 181.315 50.7256C181.315 49.3756 181.865 46.0756 184.905 46.0756C186.375 46.0756 187.125 47.0456 187.125 48.4256C187.125 49.7756 186.575 53.0756 183.535 53.0756ZM183.685 52.3156C185.875 52.3156 186.255 49.3256 186.255 48.4556C186.255 47.3856 185.695 46.8356 184.755 46.8356C182.565 46.8356 182.185 49.8256 182.185 50.6956C182.185 51.7656 182.745 52.3156 183.685 52.3156Z" fill="#007BFB"/>
        <path d="M146.43 65.6756H147.98L147.81 66.4756H143.77L143.94 65.6756H145.6L146.66 60.5656L144.74 61.4456L144.4 60.7756L146.66 59.6756H147.68L146.43 65.6756ZM151.446 65.6756H152.996L152.826 66.4756H148.786L148.956 65.6756H150.616L151.676 60.5656L149.756 61.4456L149.416 60.7756L151.676 59.6756H152.696L151.446 65.6756Z" fill="#007BFB"/>
        <path  fillRule="evenodd"  clipRule="evenodd" d="M43.6667 77L1.85504 8.05869C1.33039 7.19362 0 7.56553 0 8.57725V145.423C0 146.434 1.33039 146.806 1.85504 145.941L43.6667 77ZM87.3333 77L45.5217 8.05869C44.9971 7.19362 43.6667 7.56553 43.6667 8.57725V77V145.423C43.6667 146.434 44.9971 146.806 45.5217 145.941L87.3333 77ZM130.686 77.5186C130.879 77.1999 130.879 76.8001 130.686 76.4814L89.1884 8.05869C88.6637 7.19362 87.3333 7.56553 87.3333 8.57725V77V145.423C87.3333 146.434 88.6637 146.806 89.1884 145.941L130.686 77.5186Z" fill="#007BFB"/>
      </g>
      <defs>
        <clipPath id="clip0_336_2741">
          <rect width="512" height="152.333" fill="white" transform="translate(0 0.333496)"/>
        </clipPath>
      </defs>
    </svg>


  );
}