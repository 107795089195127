// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.request .card__image, .request .card__image img {
  max-height: 644px;
  width: auto;
  height: 100%;
}
.request .card__description {
  margin-bottom: 40px;
}
@media (max-width: 1280px) {
  .request .card .card__image {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .request .card br {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Request/Request.scss"],"names":[],"mappings":"AAIE;EACE,iBAAA;EACA,WAAA;EACA,YAAA;AAHJ;AAME;EACE,mBAAA;AAJJ;AAOE;EACE;IACE,aAAA;EALJ;AACF;AASE;EAEI;IACE,aAAA;EARN;AACF","sourcesContent":["@import \"../../scss/variables.scss\";\n\n\n.request {\n  .card__image, .card__image img {\n    max-height: 644px;\n    width: auto;\n    height: 100%;\n  }\n  \n  .card__description {\n    margin-bottom: 40px;\n  }\n\n  @media (max-width: $desktop) {\n    .card .card__image {\n      display: none;\n    }\n\n  }\n\n  @media (min-width: $smallTablet) and (max-width: $largeTablet) {\n    .card {\n      br {\n        display: none;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
