import Container from '../../hoc/Container/Container';
import Card from '../Card/Card';
import './DirectorInfo.scss';
import React from 'react';
import { directorInfo } from './utils/constants';
import Button from '../Button/Button';
import AntonElistratovIcon from '../../assets/images/mentors/AntonElistratovIcon.png';
import ArtemShusterIcon from '../../assets/images/mentors/ArtemShusterIcon.png';
import EvgenyBolshakovIcon from '../../assets/images/mentors/EvgenyBolshakovIcon.png';
import MinkoVeronikaIcon from '../../assets/images/mentors/MinkoVeronikaIcon.png';

const DirectorInfo: React.FC = () => {
  return (
    <section className="section section--grey">
      <Container>
        <div className="directorInfo">
          <div className="card card__team team">
            <div className="team__images">
              <div className="team__image">
                <img src={AntonElistratovIcon} />
              </div>
              <div className="team__image">
                <img src={EvgenyBolshakovIcon} />
              </div>
              <div className="team__image">
                <img src={ArtemShusterIcon} />
              </div>
              <div className="team__image">
                <img src={MinkoVeronikaIcon} />
              </div>
            </div>
            <div className="team__title">Команда «Самолет»</div>

            <div className="team__description">
              Блог той самой компании // <br /> SMLT TEAM
            </div>

            <a
              href={directorInfo.director.buttonLink}
              target="_blank"
              className="team__link">
              <Button className="button--white">
                {directorInfo.director.buttonText}
              </Button>
            </a>
          </div>

          <Card
            title={directorInfo.mentoringDescription.title}
            subtitle={directorInfo.mentoringDescription.subtitle}
            theme={directorInfo.mentoringDescription.theme}
            size={directorInfo.mentoringDescription.size}
            image={directorInfo.mentoringDescription.image}
            imagePosition={directorInfo.mentoringDescription.imagePosition}
            buttonLink={directorInfo.mentoringDescription.buttonLink}
            buttonText={directorInfo.mentoringDescription.buttonText}
            buttonClassName={directorInfo.mentoringDescription.buttonClassName}
          />
        </div>
      </Container>
    </section>
  );
};

export default DirectorInfo;
