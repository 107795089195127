import React from 'react';
import './Button.scss';

type ButtonType = 'button' | 'submit' | 'reset';
type ButtonSize = 'common' | 'large';

interface IButton {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: (event: any) => void;
  type?: ButtonType;
  size?: ButtonSize;
  className?: string;
}

const Button: React.FC<IButton> = (props) => {
  const {
    children,
    disabled = false,
    onClick,
    type = 'button',
    size = 'common',
    className = '',
  } = props;

  return (
    <button
      type={type}
      className={`button button--${size} ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
