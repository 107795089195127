// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background-color: #ffffff;
}
.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 64px;
}
@media (max-width: 1024px) {
  .header .container {
    padding: 16px;
    justify-content: center;
  }
}
@media (max-width: 1024px) {
  .header__button {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,yBCYM;ADbR;AAGE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;AADJ;AAGI;EANF;IAOI,aAAA;IACA,uBAAA;EAAJ;AACF;AAII;EADF;IAEI,aAAA;EADJ;AACF","sourcesContent":["@import \"../../scss/variables.scss\";\n\n.header {\n  background-color: $white;\n\n  .container {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 32px 64px;\n\n    @media (max-width: $largeTablet) {\n      padding: 16px;\n      justify-content: center;\n    }\n  }\n\n  &__button {\n    @media (max-width: $largeTablet) {\n      display: none;\n    }\n  }\n\n}\n","$mobile: 390px;\n$smallTablet: 768px;\n$largeTablet: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1440px;\n$wideDesktop: 1600px;\n\n// colors\n$black: #141414;\n$blue: #007bfb;\n$blueHover: #3395fc;\n$lightBlue: #e0efff;\n$darkBlue: #1F78FF;\n$gray: #788095;\n$lightGray: #f4f6fb;\n$white: #ffffff;\n$red: #ED584E;\n$lightRed: #f17067;\n\n//radius\n$largeCardRadius: 32px;\n$largeCardMobileRadius: 24px;\n$mediumCardRadius: 16px;\n$smallCardRadius: 8px;\n$buttonRadius: 80px;\n\n@media (max-width: $largeTablet) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
