import './Step.scss';
import React, { useState } from 'react';
import RhombusIcon from '../../../assets/images/RhombusIcon';
import ArrowDownIcon from '../../../assets/images/ArrowDownIcon';

interface IStep {
  count: number;
  date: string;
  title: string | React.ReactElement;
  description: React.ReactElement;
}

const Step: React.FC<IStep> = ({ count, date, description, title }) => {
  const [descriptionOpen, setDescriptionOpen] = useState(false);

  const isMainStep = count === 9;

  const handleOpenDescriptionBtnClick = () => {
    setDescriptionOpen(prev => !prev);
  };

  return (
    <div className={'step' + (descriptionOpen ? ' open' : '')}>
      <div className="step__count">
        <div className="step__count__value">{count}</div>
        <div className="step__count__line"></div>
      </div>
      <div className="step__line"></div>
      <div className="step__card">
        <div className="step__card__date">
          {isMainStep && <RhombusIcon />}
          {date}
        </div>
        <button
          className={'step__card__btn' + (descriptionOpen ? ' open' : '')}
          onClick={handleOpenDescriptionBtnClick}>
          <ArrowDownIcon />
        </button>
        <div className="step__card__text">
          <div className="step__card__title">{title}</div>
          <div className="step__card__description">{description}</div>
        </div>
      </div>
    </div>
  );
};

export default Step;
