// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo img {
  height: 20px;
}
@media only screen and (min-width: 768px) {
  .logo img {
    height: 28px;
  }
}
.logo a {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Logo/Logo.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AADF;AAGE;EACE,YAAA;AADJ;AAGI;EAHF;IAII,YAAA;EAAJ;AACF;AAGE;EACE,aAAA;EACA,mBAAA;AADJ","sourcesContent":["@import \"../../scss/variables.scss\";\n\n.logo {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  img {\n    height: 20px;\n\n    @media only screen and (min-width: $smallTablet) {\n      height: 28px;\n    }\n  }\n\n  a {\n    display: flex;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
