// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mentors {
  display: flex;
  flex-direction: column;
  gap: 64px;
}
@media (max-width: 1024px) {
  .mentors {
    gap: 32px;
  }
}
.mentors__title {
  color: #ffffff;
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 80px */
  letter-spacing: -0.02em;
}
@media (max-width: 1024px) {
  .mentors__title {
    font-size: 32px;
  }
}
.mentors__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1280px) {
  .mentors__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    row-gap: 32px;
  }
}
@media (max-width: 1024px) {
  .mentors__list {
    margin-bottom: 8px;
  }
}
@media (max-width: 768px) {
  .mentors__list {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Mentors/Mentors.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EAEA,SAAA;AAFF;AAIE;EANF;IAOI,SAAA;EADF;AACF;AAGE;EACE,cCEI;EDDJ,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,uBAAA;AADJ;AAGI;EARF;IASI,eAAA;EAAJ;AACF;AAGE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AADJ;AAGI;EALF;IAMI,aAAA;IACA,qCAAA;IACA,kCAAA;IACA,aAAA;EAAJ;AACF;AAEI;EAZF;IAaI,kBAAA;EACJ;AACF;AACI;EAhBF;IAiBI,aAAA;IACA,sBAAA;IACA,SAAA;EAEJ;AACF","sourcesContent":["@import \"../../scss/variables.scss\";\n\n.mentors {\n  display: flex;\n  flex-direction: column;\n\n  gap: 64px;\n\n  @media (max-width: $largeTablet) {\n    gap: 32px;\n  }\n\n  &__title {\n    color: $white;\n    font-size: 80px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 100%; /* 80px */\n    letter-spacing: -0.02em;\n\n    @media (max-width: $largeTablet) {\n      font-size: 32px;\n    }\n  }\n\n  &__list {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    @media (max-width: $desktop) {\n      display: grid;\n      grid-template-columns: repeat(2, 1fr);\n      grid-template-rows: repeat(2, 1fr);\n      row-gap: 32px;\n    }\n\n    @media (max-width: $largeTablet) {\n      margin-bottom: 8px;\n    }\n\n    @media (max-width: $smallTablet) {\n      display: flex;\n      flex-direction: column;\n      gap: 40px;\n    }\n  }\n}\n","$mobile: 390px;\n$smallTablet: 768px;\n$largeTablet: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1440px;\n$wideDesktop: 1600px;\n\n// colors\n$black: #141414;\n$blue: #007bfb;\n$blueHover: #3395fc;\n$lightBlue: #e0efff;\n$darkBlue: #1F78FF;\n$gray: #788095;\n$lightGray: #f4f6fb;\n$white: #ffffff;\n$red: #ED584E;\n$lightRed: #f17067;\n\n//radius\n$largeCardRadius: 32px;\n$largeCardMobileRadius: 24px;\n$mediumCardRadius: 16px;\n$smallCardRadius: 8px;\n$buttonRadius: 80px;\n\n@media (max-width: $largeTablet) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
