import './Request.scss';
import Container from '../../hoc/Container/Container';
import Card from '../Card/Card';
import React from 'react';
import { requestInfo } from './utils/constants';
import ym from 'react-yandex-metrika';

interface IRequest {
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const Request: React.FC<IRequest> = ({ handleClick }) => {
  return (
    <section className="section section--white">
      <Container>
        <div className="request">
          <Card
            title={requestInfo.title}
            subtitle={requestInfo.subtitle}
            theme={requestInfo.theme}
            image={requestInfo.image}
            imagePosition={requestInfo.imagePosition}
            size={requestInfo.size}
            buttonText={requestInfo.buttonText}
            buttonClick={event => {
              handleClick(event);
              ym('reachGoal', 'bottom-hero');
            }}
          />
        </div>
      </Container>
    </section>
  );
};

export default Request;
