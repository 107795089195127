// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section--grey {
  background-color: #f4f6fb;
}
.section--blue {
  background-color: #007bfb;
}
.section--white {
  background-color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/pages/Index/Index.scss","webpack://./src/scss/variables.scss"],"names":[],"mappings":"AAGE;EACE,yBCUQ;ADZZ;AAKE;EACE,yBCCG;ADJP;AAME;EACE,yBCGI;ADPR","sourcesContent":["@import \"../../scss/variables.scss\";\n\n.section {\n  &--grey {\n    background-color: $lightGray;\n  }\n  \n  &--blue {\n    background-color: $blue;\n  }\n  \n  &--white {\n    background-color: $white;\n  }\n}","$mobile: 390px;\n$smallTablet: 768px;\n$largeTablet: 1024px;\n$desktop: 1280px;\n$largeDesktop: 1440px;\n$wideDesktop: 1600px;\n\n// colors\n$black: #141414;\n$blue: #007bfb;\n$blueHover: #3395fc;\n$lightBlue: #e0efff;\n$darkBlue: #1F78FF;\n$gray: #788095;\n$lightGray: #f4f6fb;\n$white: #ffffff;\n$red: #ED584E;\n$lightRed: #f17067;\n\n//radius\n$largeCardRadius: 32px;\n$largeCardMobileRadius: 24px;\n$mediumCardRadius: 16px;\n$smallCardRadius: 8px;\n$buttonRadius: 80px;\n\n@media (max-width: $largeTablet) {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
