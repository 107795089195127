import React from 'react';
import Logo from '../Logo/Logo';
import './Header.scss';
import Button from '../Button/Button';
import Container from '../../hoc/Container/Container';

interface IHeader {
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const Header: React.FC<IHeader> = ({ handleClick }) => {
  
  return (
    <header className='header'>
      <Container>
        <Logo />
        <div className='header__button'>
          <Button onClick={handleClick}>Хочу месяц наставничества</Button>
        </div>
      </Container>
    </header>
  );
};

export default Header;
