import React from 'react';
import './Container.scss';

interface IContainer {
  children: React.ReactNode;
  isResultPage?: boolean;
}

const Container: React.FC<IContainer> = (props) => {
  const { children, isResultPage } = props;

  return <div className={!isResultPage ? 'container' : ''}>{children}</div>;
};

export default Container;
