import React from 'react';

interface ICardImage {
  src: any;
  position: string;
  alt: string;
}

const CardImage: React.FC<ICardImage> = ({ src, position, alt }) => {
  return (
    <div className={`card__image card__image--${position}`}>
      <img src={src} alt={alt} />
    </div>
  );
};

export default CardImage;