import React from 'react';
import './Footer.scss';
import Container from '../../hoc/Container/Container';
import Logo from '../Logo/Logo';
import ArrowIcon from '../../assets/images/ArrowIcon';
import TelegramIcon from '../../assets/images/TelegramIcon';
import VkontakteIcon from '../../assets/images/VkontakteIcon';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <Container>
        <Logo />
        <a
          href="https://career.samolet.ru/"
          target={'_blank'}
          className="footer__link">
          career.samolet.ru
          <button className="footer__link__btn">
            <ArrowIcon />
          </button>
        </a>
        <div className="footer__links">
          <div className="footer__links__left">
            <a className="footer__link__agency" href="https://arrr.agency/">
              Сделано в arrr.agency
            </a>
          </div>
          <div className="footer__links__right">
            <a
              className="footer__links__item"
              href="https://t.me/+AVe8k6qUUuY5M2Iy"
              target="_blank">
              <button className="footer__links__item__btn">
                <TelegramIcon />
              </button>
            </a>
            <a
              className="footer__links__item"
              href="https://vk.com/samoletru"
              target="_blank">
              <button className="footer__links__item__btn">
                <VkontakteIcon />
              </button>
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
