import './Mentors.scss';
import Container from '../../hoc/Container/Container';
import React from 'react';
import MentorCard from './MentorCard/MentorCard';
import Button from '../Button/Button';
import { mentors } from './utils/constants';
import ym from 'react-yandex-metrika';

interface IMentors {
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const Mentors: React.FC<IMentors> = ({ handleClick }) => {
  return (
    <section className="section section--blue">
      <Container>
        <div className="mentors">
          <div className="mentors__title">Наставники</div>
          <div className="mentors__list">
            {mentors.map(mentor => {
              return (
                <MentorCard
                  key={mentor.name}
                  name={mentor.name}
                  image={mentor.image}
                  description={mentor.description}
                  post={mentor.post}
                />
              );
            })}
          </div>
          <Button
            onClick={event => {
              handleClick(event);
              ym('reachGoal', 'mentors-cta');
            }}
            className="button--white">
            Хочу месяц наставничества
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default Mentors;
