import React from 'react';
import WomanWithLaptop from '../../../assets/images/woman/WomanWithLaptop.png';
import InfinityIcon from '../../../assets/images/InfinityIcon';

interface ICard {
  title: string | JSX.Element;
  subtitle: string | JSX.Element;
  buttonText?: string;
  theme: string;
  img?: string;
}

interface ICards {
  mentoringMonth: ICard;
  mentoringDates: ICard;
  workshop: ICard;
  meetings: ICard;
  mentors: ICard;
  careerValue: ICard;
}

export const ABOUT_CARDS: ICards = {
  mentoringMonth: {
    title: 'Месяц наставничества',
    subtitle: (
      <div>
        Дарим поддержку и&nbsp;экспертное мнение! Без&nbsp;оплаты, донатов
        и&nbsp;неинтересных условий. Мы&nbsp;здесь, чтобы помочь.
      </div>
    ),
    buttonText: 'Оставить заявку',
    theme: 'blue',
    img: WomanWithLaptop,
  },
  mentoringDates: {
    title: (
      <div>
        01.02 <br />– 01.03
      </div>
    ),
    subtitle: (
      <div>
        <div>
          Пользоваться помощью — это нормально. Каждому из нас рано
          или&nbsp;поздно нужен опытный наставник, который твердо возьмет за
          руку и&nbsp;сократит запутанный карьерный лабиринт до простой
          и&nbsp;понятной прямой схемы.
        </div>
        <br />
        <div>
          Самолет дарит месячный интенсив с&nbsp;четырьмя наставниками:
          по&nbsp;горизонтальному и вертикальному росту, для начинающих
          руководителей и&nbsp;для тех, кто только хочет стать лидером проекта.
        </div>
        <br />
        <div>
          Оставьте заявку и выберите одного из&nbsp;четырех экспертов
          уже&nbsp;сейчас!
        </div>
      </div>
    ),
    img: WomanWithLaptop,
    theme: 'white',
  },
  workshop: {
    title: '4',
    subtitle: 'Полезных воркшопа',
    theme: 'blue',
  },
  meetings: {
    title: '4',
    subtitle: <div>Встречи с&nbsp;наставником</div>,
    theme: 'blue',
  },
  mentors: {
    title: '4',
    subtitle: 'Эксперта',
    theme: 'blue',
  },
  careerValue: {
    title: <InfinityIcon />,
    subtitle: 'Ценности для карьеры',
    theme: 'blue',
  },
};
