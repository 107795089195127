export default function ArrowIcon() {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M40.9856 10.0768L0.796801 50.2656L7.7344 57.2031L47.9232 17.0144V46.7967L57.7344 46.7968L57.7344 0.26555L11.2032 0.265553L11.2032 10.0768L40.9856 10.0768Z"
        fill="#007BFB"
      />
    </svg>
  );
}
