export default function MaterialOne() {
  return (
    <svg width='310' height='131' viewBox='0 0 310 131' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g  clipPath='url(#clip0_336_2266)'>
        <rect width='309.333' height='130.333' fill='white' />
        <path  fillRule='evenodd'  clipRule='evenodd'
              d='M149.872 55.5646C154.235 45.5214 165.929 40.9495 175.99 45.3531L176.498 45.5756L176.271 45.0693C171.778 35.048 176.245 23.3141 186.249 18.8609C196.253 14.4077 208.005 18.9216 212.499 28.943L237.061 107.734L160.187 81.7229C150.126 77.3193 145.508 65.6078 149.872 55.5646Z'
              fill='white' stroke='#007BFB'  strokeWidth='1.5'  strokeLinecap='round'  strokeLinejoin='round'
               strokeDasharray='5 5' />
        <path  fillRule='evenodd'  clipRule='evenodd'
              d='M18.417 42.0148C26.7882 34.9555 39.2956 36.0544 46.353 44.4692C46.4789 44.6192 46.7235 44.5356 46.7311 44.3399C47.1576 33.3656 56.3726 24.8377 67.3135 25.2923C78.2543 25.7468 86.7779 35.0117 86.3515 45.9859L73.9727 123.609C73.7153 125.223 71.7359 125.864 70.5817 124.707L16.0384 70.0329C8.98092 61.6181 10.0459 49.074 18.417 42.0148Z'
              fill='#007BFB' />
        <path  fillRule='evenodd'  clipRule='evenodd'
              d='M183.269 91.9095C177.436 101.177 165.185 103.926 155.906 98.0512C155.741 97.9464 155.533 98.1004 155.585 98.2891C158.51 108.875 152.319 119.798 141.756 122.686C131.194 125.574 120.26 119.334 117.335 108.748L105.566 31.0295C105.322 29.4136 107.013 28.2023 108.464 28.9544L177.03 64.4915C186.309 70.3668 189.102 82.6423 183.269 91.9095Z'
              fill='#007BFB' />
        <path  fillRule='evenodd'  clipRule='evenodd'
              d='M93.3352 3.70192C104.239 2.69484 113.873 10.746 114.854 21.6846L114.904 22.2372L115.238 21.7946C121.861 13.0339 134.297 11.3041 143.014 17.9311C151.732 24.5581 153.43 37.0323 146.807 45.7931L89.5548 105.236L75.3677 25.3316C74.387 14.3929 82.4313 4.70899 93.3352 3.70192Z'
              fill='white' stroke='#007BFB'  strokeWidth='1.5'  strokeLinecap='round'  strokeLinejoin='round'
               strokeDasharray='5 5' />
        <path  fillRule='evenodd'  clipRule='evenodd'
              d='M267.952 9.30755C276.891 15.6326 278.975 28.0139 272.607 36.962C272.494 37.1216 272.636 37.3373 272.828 37.2954C283.556 34.9461 294.129 41.7182 296.442 52.4213C298.756 63.1243 291.935 73.7054 281.206 76.0546L202.966 83.6107C201.339 83.7678 200.221 82.0134 201.05 80.6051L240.237 14.0571C246.605 5.10896 259.013 2.98252 267.952 9.30755Z'
              fill='#007BFB' />
      </g>
      <defs>
        <clipPath id='clip0_336_2266'>
          <rect width='309.333' height='130.333' fill='white' />
        </clipPath>
      </defs>
    </svg>

  );
}