import Container from '../../hoc/Container/Container';
import { CardSize, CardTheme } from '../../utils/constants';
import Card from '../Card/Card';
import React from 'react';
import './FAQ.scss';
import Button from '../Button/Button';
import { faqList } from './utils/constants';
import ym from 'react-yandex-metrika';

interface IFAQ {
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const FAQ: React.FC<IFAQ> = ({ handleClick }) => {
  return (
    <section className="section section--white">
      <Container>
        <div className="faq">
          <div className="faq__title">Вопросы и ответы</div>
          <div className="faq__list">
            {faqList.map((faqItem, index) => {
              return (
                <Card
                  key={index}
                  title={faqItem.question}
                  subtitle={faqItem.answer}
                  theme={CardTheme.grey}
                  size={CardSize.medium}
                />
              );
            })}
          </div>
          <div className="faq__btn">
            <div className="faq__btn__label">
              Остались вопросы или предложения?
            </div>
            <a href="mailto:samoletgroup3@gmail.com" className="faq__btn__link">
              <Button
                onClick={event => {
                  ym('reachGoal', 'footer-cta');
                }}
                className="button--grey">
                Напишите нам
              </Button>
            </a>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FAQ;
